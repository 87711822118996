import {React, useEffect, useRef, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './Components/About';
import Careers from './Components/Careers';
import Contact from './Components/Contact';
import Expertise from './Components/Expertise';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Nav from './Components/Nav';
import News from './Components/News';
import People from './Components/People';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewsDetail from './Components/NewsDetail';
import NotFound from './Components/404';
import $ from "jquery"
import "./App.scss";
function App() {
const [preloader, setPreloader] = useState(true);
const [timer, setTimer] = useState(2);
const id = useRef(null);

const clear = ()=>{
  window.clearInterval(id.current);
  setPreloader(false);
}

useEffect(()=>{
  id.current = window.setInterval(()=>{
    setTimer((timer) => timer-1)
  },1350);
},[])

useEffect(()=>{
  if(timer === 0) {
    clear();
  }
},[timer])


const loader = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  
  $(".loader-wrapper").addClass('showup')
  if (mobile === true) {
    setTimeout(()=>{
      $(".loader-wrapper").removeClass('showon')
      $(".loader-wrapper").addClass('showoff')
      },1700)
  } else {
    setTimeout(()=>{
      $(".loader-wrapper").removeClass('showon')
      $(".loader-wrapper").addClass('showoff')
      },1700)
  }
}
useEffect(() => {
  loader();
})
  return (
    <div>
    {preloader ? ( 
    <div className="loader-wrapper">
    </div> 
    ):( 
    <div >
        <Nav/>
        <Router>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/expertise' element={<Expertise/>} />
            <Route path='/lawyers' element={<People/>} />
            <Route path='/careers' element={<Careers/>} />
            <Route path='/news' element={<News/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path='/news/:id' element={<NewsDetail/>} />
            <Route path='*' element={<NotFound/>} />
          </Routes>
        </Router>
        <Footer/>
    </div>
  )}
  </div>
  );
}

export default App;
