import React from 'react'
import "../Style/Careers.scss"
import Banner from "../Assets/Careers/CROWD.png";
import {color, motion} from "framer-motion"
import { Button } from 'react-bootstrap';
function Careers() {
    return (
        <div className='Careers-Container'>
            <div className='Careers-Section-1'>
                <motion.img
                initial={{
                    opacity:0
                }}
                whileInView={{
                    opacity:1
                }}
                transition={{
                    duration: 0.5,
                    delay:0.2
                }}
                viewport={{
                    once:true
                }}
                src={Banner} />
                <div className='Careers-Content'>
                    <motion.h1
                    initial={{
                        opacity:0,
                        y:100
                    }}
                    whileInView={{
                        opacity:1,
                        y:0
                    }}
                    transition={{
                        duration: 0.5,
                        delay:0.2
                    }}
                    viewport={{
                        once:true
                    }}
                    >Careers</motion.h1>
                    <motion.p
                    initial={{
                        opacity:0,
                        y:100
                    }}
                    whileInView={{
                        opacity:1,
                        y:0
                    }}
                    transition={{
                        duration: 0.5,
                        delay:0.4
                    }}
                    viewport={{
                        once:true
                    }}
                    >We are seeking top-performing individuals who possess a deep understanding of the legal system and use it to drive positive change.
                        If you fit this description and have a strong portfolio, we encourage you to submit your application for consideration.
                    </motion.p>
                    <Button>
                            <a href='/contact' >Contact</a>
                    </Button>
                </div>
            </div>
            {/* <div className='Careers-Section-3'>
                <div className='Left'>
                    <motion.h2
                    initial={{
                        opacity:0,
                        y:100
                    }}
                    whileInView={{
                        opacity:1,
                        y:0
                    }}
                    transition={{
                        duration: 0.5,
                        delay:0.2
                    }}
                    viewport={{
                        once:true
                    }}
                    >As a Mataram Partners, you will enjoy the following benefits</motion.h2>
                </div>
                <div className='Right'>
                    <motion.p
                    initial={{
                        opacity:0,
                        y:100
                    }}
                    whileInView={{
                        opacity:1,
                        y:0
                    }}
                    transition={{
                        duration: 0.5,
                        delay:0.2
                    }}
                    viewport={{
                        once:true
                    }}
                    >At Mataram Partners, we strive for a balance between hard work and leisure. Our office is situated in the vibrant Treasury Tower, and we regularly organize team-building trips and cultural excursions to enhance our professional development and foster a positive working environment.
                        <span>We also recognize the importance of personal growth, which is why each team member is allocated a budget for professional development opportunities of their choice.</span>
                        <span>In addition to this, we provide additional support to our team members by taking care of practical matters such as personal tax returns to assist with financial management.</span>
                    </motion.p>
                    <motion.a className='contact-btn' href='/contact'
                    initial={{
                        opacity:0,
                        y:100
                    }}
                    whileInView={{
                        opacity:1,
                        y:0
                    }}
                    transition={{
                        duration: 0.5,
                        delay:0.2
                    }}
                    viewport={{
                        once:true
                    }}>
                        <span>
                            Contact Us
                        </span>
                    </motion.a>
                </div>
            </div> */}
        </div>
    )
}
export default Careers;