import React from 'react'
import "../Style/About.scss";
import Image1 from "../Assets/Aboutus/about-1.webp";
import Image2 from "../Assets/Aboutus/Aboutus-2.png";
import Image3 from "../Assets/Aboutus/Aboutus-3.png";
import {motion} from "framer-motion"
function About() {
  return (
    <div className='About-Container'>
      <div className='About-Section-1'>
        <motion.img
        initial={{
          opacity:0,
        }}
        whileInView={{
          opacity:1,
        }}
        transition={{
          duration:0.5,
          delay:0.3
        }}
        viewport={{ once: true}}
        src={Image1} />
        <div className='About-Content'>
          <motion.h1
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          >Comprehensive <span>legal services</span></motion.h1>
          <motion.p
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
          transition={{
            duration:0.5,
            delay:0.4
          }}
          viewport={{ once: true}}
          >Serving the needs of foreign investors in Indonesia.</motion.p>
        </div>
      </div>
      <div className='About-Section-2'>
        <div className='About-Title'>
          <motion.h2
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          >ABOUT US</motion.h2>
        </div>
        <motion.span
        initial={{
          width:"0%"
        }}
        whileInView={{
          width:"100%"
        }}
        transition={{
          duration:0.8,
          delay:0.2
        }}
        viewport={{ once: true}}
        className='Line' />
        <div className='About-Description'>
          <div className='Left'>
            <motion.p
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.2
            }}
            viewport={{ once: true}}
            >Mataram Partners is an Indonesian law firm specialising in foreign investor services in Indonesia.
              <motion.span
              initial={{
                opacity:0,
                y:100
              }}
              whileInView={{
                opacity:1,
                y:0
              }}
              transition={{
                duration:0.5,
                delay:0.4
              }}
              viewport={{ once: true}}
              >
                Our dedicated team is comprised of legal advisers with a diverse array of academic and professional backgrounds, fully fluent in English. The legal advice we provide to our varied international clients is clear, concise and engaging with a sound knowledge of Indonesian law.</motion.span>
              <motion.span
              initial={{
                opacity:0,
                y:100
              }}
              whileInView={{
                opacity:1,
                y:0
              }}
              transition={{
                duration:0.5,
                delay:0.6
              }}
              viewport={{ once: true}}
              >Through our team’s background and expertise developed at major international law firms, we fully understand the needs of our international clients and aim to meet and surpass their expectations every time.</motion.span>
            </motion.p>
          </div>
          <div className='Right'>
            <motion.img
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.2
            }}
            viewport={{ once: true}}
            src={Image2}/>
            <motion.img 
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.4
            }}
            viewport={{ once: true}}
            src={Image3} className="margin-top-20"/>
          </div>
        </div>
      </div>
    </div>
  )
}
export default About;