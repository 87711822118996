import React from 'react'
import '../Style/notfound.scss';
function NotFound() {
  return (
    <div>
        <h1>
            PAGE NOT FOUND
        </h1>
    </div>
  )
}

export default NotFound;