import React from 'react'
import "../Style/Footer.scss";

function Footer() {
  return (
    <div className='Footer-Container'>
      <span className='Line' />
      <a href='https://clay-ch.com/' target="_blank">
        <p>Website by Clay</p>
      </a>
    </div>
  )
}
export default Footer;