import React from "react";
import "../Style/Expertise.scss";
import { useState } from "react";
import Banner from "../Assets/Expertise/BANNER-Expertise.png";
import Image1 from "../Assets/Expertise/Expertise-1.png";
import Image2 from "../Assets/Expertise/Expertise-2.png";
import Datas from "../Data/Expertise-Data.json";
import { Accordion } from "react-bootstrap";
import { delay, motion } from "framer-motion";
function Expertise() {
  const [Data, setData] = useState("Default");
  const [Active, setActive] = useState(false);
  // const filteredData = Datas.filter((item) => item.list !== null && item.list !== undefined);
  const isActive = (index) => {
    if (Data === index) {
    } else {
    }
  };
  console.log(Datas);
  return (
    <div className="Expertise-Container">
      <div className="Expertise-Section-1">
        <motion.img
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
          }}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
          viewport={{ once: true }}
          src={Banner}
          alt="Banner-Expertise"
        />
        <div className="Expertise-Content">
          <motion.h1
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.4,
            }}
            viewport={{ once: true }}
          >
            Understanding your <span>business</span>
          </motion.h1>
          <motion.p
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.6,
            }}
            viewport={{ once: true }}
          >
            Through understanding of Indonesian law and business
            <span>practice with an international outlook.</span>
          </motion.p>
        </div>
      </div>
      <div className="Expertise-Section-2">
        <div className="Expertise-Title">
          <motion.h2
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
            }}
            viewport={{ once: true }}
          >
            EXPERTISE
          </motion.h2>
        </div>
        <motion.span
          initial={{
            width: "0%",
          }}
          whileInView={{
            width: "100%",
          }}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
          viewport={{ once: true }}
          className="Line"
        />
        <div className="Expertise-Description">
          <div className="Left">
            <motion.p
              initial={{
                opacity: 0,
                y: 100,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
            >
              Our practice encompasses virtually all areas of civil and
              commercial law in Indonesia across a diverse range of industries.
              <motion.span
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.4,
                }}
                viewport={{ once: true }}
              >
                Our team of experts aim to provide tailor made, innovative and
                practical services to our clients.
              </motion.span>
              <motion.span
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.6,
                }}
                viewport={{ once: true }}
              >
                Through our experience with major international and domestic
                clients and regional stakeholders we understand what it takes to
                achieve commercial success in the local environment.
              </motion.span>
              <motion.span
                initial={{
                  opacity: 0,
                  y: 100,
                }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.6,
                }}
                viewport={{ once: true }}
              >
                For further details regarding our practice in a specific
                industry or area of law, please select a practice area from the
                list on the left hand side navigation.
              </motion.span>
            </motion.p>
          </div>
          <div className="Right">
            <motion.img
              initial={{
                opacity: 0,
                y: 100,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              src={Image1}
            />
          </div>
        </div>
        <motion.span
          initial={{
            width: "0%",
          }}
          whileInView={{
            width: "100%",
          }}
          transition={{
            duration: 0.5,
            delay: 0.2,
          }}
          viewport={{ once: true }}
          className="Line"
        />
      </div>
      <div className="Expertise-Section-3">
        <div className="Section-3-Title">
          <motion.h2
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.5,
              delay: 0.2,
            }}
            viewport={{ once: true }}
          >
            Practice Areas
          </motion.h2>
        </div>
        <div className="Section-3-Content-Desktop">
          <div className="Button-Section">
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Mining" ? "active" : ""}
              onClick={() => setData("Mining")}
            >
              <span>Mining & Natural Resources</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Compe" ? "active " : ""}
              onClick={() => setData("Compe")}
            >
              <span>Competition</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Employment" ? "active " : ""}
              onClick={() => setData("Employment")}
            >
              <span>Employment</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Oil" ? "active " : ""}
              onClick={() => setData("Oil")}
            >
              <span>Oil & Gas</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Real" ? "active " : ""}
              onClick={() => setData("Real")}
            >
              <span>Real Estate, Construction & Hotels</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Restructur" ? "active " : ""}
              onClick={() => setData("Restructur")}
            >
              <span>Restructuring & Insolvency</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Trade" ? "active " : ""}
              onClick={() => setData("Trade")}
            >
              <span>Trade & Commerce</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Banking" ? "active " : ""}
              onClick={() => setData("Banking")}
            >
              <span>Banking & Finance</span>
            </motion.button>

            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Disputes" ? "active " : ""}
              onClick={() => setData("Disputes")}
            >
              <span>Disputes</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Capital" ? "active " : ""}
              onClick={() => setData("Capital")}
            >
              <span>M&A and Capital Markets</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Power" ? "active " : ""}
              onClick={() => setData("Power")}
            >
              <span>Power & Infrastructure</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Shipping" ? "active " : ""}
              onClick={() => setData("Shipping")}
            >
              <span>Shipping & Logistics</span>
            </motion.button>
            <motion.button
              initial={{
                opacity: 0,
              }}
              whileInView={{
                opacity: 1,
              }}
              transition={{
                duration: 0.5,
                delay: 0.2,
              }}
              viewport={{ once: true }}
              className={Data === "Tele" ? "active " : ""}
              onClick={() => setData("Tele")}
            >
              <span>Telecommunications, Media & Technology</span>
            </motion.button>
          </div>
          <div className="Content-Section">
            <div>{Data === "Default" && <img src={Image2} />}</div>
            <div>
              {Data === "Mining" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Mining & Natural Resouces</h2>
                  <p>
                    Our lawyers have worked on numerous mining projects
                    throughout Indonesia, acting for sponsors, lenders,
                    operators and off-takers on virtually all aspects of mining.
                  </p>
                  <span>
                    In particular, we have advised clients with regard to:
                  </span>
                  <ul>
                    <li>Authorizations to mine (ore) and Contracts of Work</li>
                    <li>Environmental and other regulatory matters</li>
                    <li>Technical services and operating agreements</li>
                    <li>Purchase and sale agreements for minerals</li>
                    <li>
                      Collateral management and stockpile management agreements
                    </li>
                    <li>Security over minerals and commodities</li>
                    <li>Sale and acquisition of mining projects</li>
                  </ul>
                  <span>
                    Our lawyers have also advised clients on financing and
                    corporate matters in the plantation sector.
                  </span>
                </motion.div>
              )}
            </div>
            <div>
              {Data === "Banking" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Banking & Finance</h2>
                  <p>
                    We represent international lenders, sponsors and borrowers
                    in a broad array of banking and finance transactions in
                    Indonesia, particularly project financings, structured
                    commodity finance, and other forms of asset-based finance.
                  </p>
                  <p>
                    Our lawyers regularly assist in structuring, negotiating,
                    documenting and closing financings. We have particular
                    expertise in structuring, drafting, executing and perfecting
                    Indonesian security packages, including security over land,
                    plant & equipment, receivables, commodities and shares.
                  </p>
                  <p>
                    We also carry out due diligence, prepare filings in
                    connection with foreign debt registration and provide
                    Indonesian legal opinions.
                  </p>
                </motion.div>
              )}
            </div>
            <motion.div>
              {Data === "Compe" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Competition</h2>
                  <p>
                    Indonesia has quite comprehensive legislation on monopolies
                    and unfair business practices in 1999, the enforcement of
                    which is overseen by the Business Competition Supervisory
                    Commission (or “KPPU”).
                  </p>
                  <p>
                    Our lawyers have advised extensively on competition law,
                    including the Indonesian aspects of major cross-border
                    mergers and acquisitions, restrictions on vertical
                    integration and the imposition of restraints on
                    distributors.
                  </p>
                  <p>
                    We also act for clients in making submissions to the KPPU.
                  </p>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Disputes" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Disputes</h2>
                  <p>
                    The firm is active in defending clients against civil
                    actions brought against them in Indonesian courts and has
                    been consistently successful in this. We also handle
                    arbitration work and can supply expert witnesses on
                    Indonesian law.
                  </p>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Employment" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Employment</h2>
                  <p>
                    We are regularly called upon by clients to advise on
                    employment law matters which often arise in connection with
                    the establishment of wholly foreign-owned companies in
                    Indonesia, the acquisition of Indonesian companies and other
                    foreign investment transactions.
                  </p>
                  <span>
                    The firm offers a full range of employment law services to
                    meet the needs of clients in this increasingly complex area
                    of Indonesian law. In particular, we advise on:
                  </span>
                  <ul>
                    <li>Employment agreements</li>
                    <li>
                      Employment and residency visas for expatriate employees
                    </li>
                    <li>
                      Company regulations and collective labour agreements
                    </li>
                    <li>Mandatory training programs</li>
                    <li>Government social security plans (BPJS)</li>
                    <li>Employee termination and severance</li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Capital" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>M&A and Capital Markets</h2>
                  <p>
                    Our lawyers are thoroughly experienced in mergers and
                    acquisitions, including transactions for Fortune 500
                    companies. Our practice covers a broad range of industries
                    and comprises due diligence exercises, the structuring,
                    negotiation and implementation of joint ventures, share
                    acquisitions, asset acquisitions and corporate
                    reorganizations.
                  </p>
                  <p>
                    We also assist in obtaining the necessary approvals of the
                    Capital Investment Coordination Board for the establishment
                    of wholly and partially foreign-owned companies and the
                    acquisition by foreign parties of shares in existing
                    Indonesian companies. In addition, our lawyers advise
                    foreign investors on Indonesian capital markets legislation
                    and regulations in connection with the acquisition of shares
                    in companies listed on the Indonesia Stock Exchanges.
                  </p>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Oil" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Oil & Gas</h2>
                  <p>
                    Our lawyers have acted for major oil & gas companies in the
                    acquisition and development of hydrocarbon assets by way of
                    corporate purchases, asset purchases and farm-ins, including
                    some of the largest transactions in Indonesia in recent
                    years. This has entailed work on production sharing
                    contracts, technical assistance contracts, joint operating
                    agreements, and due diligence on existing operations.
                  </p>
                  <p>
                    We have also advised clients (including oil majors) on
                    abandonment, unitization, procurement, and numerous other
                    aspects of oilfield development and operation.
                  </p>
                  <p>
                    In addition, we have acted on various matters relating to
                    offshore and onshore oilfield services, including entry into
                    the Indonesian market by the establishment of Indonesian
                    subsidiaries and the acquisition of shares in existing
                    market players.
                  </p>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Power" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Power & Infrastructure</h2>
                  <p>
                    We advise on a wide array of power generation and
                    infrastructure-related matters in Indonesia.
                  </p>
                  <span>
                    Our lawyers have acted for sponsors, lenders, equity
                    investors, off-takers and contractors, and are experienced
                    in all key project documentation, including:
                  </span>
                  <ul>
                    <li>Limited and non-recourse credit facilities</li>
                    <li>Direct agreements and consents</li>
                    <li>Joint venture and shareholders agreements</li>
                    <li>Transportation agreements</li>
                    <li>Off-take agreements</li>
                    <li>Fuel supply agreements</li>
                    <li>O&M agreements</li>
                    <li>EPC contracts</li>
                  </ul>
                  <span>
                    We also advise clients with regard to relevant regulatory
                    requirements, including environmental law and land usage.
                  </span>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Real" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Real Estate, Construction & Hotels</h2>
                  <p>
                    We have advised developers and contractors on a wide range
                    of property developments.
                  </p>
                  <span>
                    In addition, we routinely handle the real property aspects
                    of other projects. Our expertise includes:
                  </span>
                  <ul>
                    <li>Land procurement issues</li>
                    <li>
                      Environmental impact assessment and permitting
                      requirements
                    </li>
                    <li>Development agreements</li>
                    <li>Leases</li>
                    <li>Title investigations</li>
                    <li>Building and engineering contracts</li>
                    <li>Consultancy agreements</li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Shipping" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Shipping & Logistics</h2>
                  <span>
                    We have advised on a broad range of contentious and
                    non-contentious matters in the shipping and logistics
                    industries. These have included:
                  </span>
                  <ul>
                    <li>Advising on the arrest of vessels</li>
                    <li>Advising on issues arising with the Indonesian Navy</li>
                    <li>
                      The establishment of joint ventures in the shipping sector
                    </li>
                    <li>
                      Advice on regulatory issues in the shipping and logistics
                      sector, particularly with regard to foreign participation
                    </li>
                    <li>Advice on the effects of Indonesia’s cabotage laws</li>
                    <li>Assistance in obtaining shipping licences</li>
                    <li>
                      Corporate restructuring in the shipping and logistics
                      industries
                    </li>
                    <li>Expert testimony on Indonesian law</li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Restructur" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Restructuring & Insolvency</h2>
                  <p>
                    In the decade since the 1997 financial crisis, our lawyers
                    have worked on all aspects of restructuring, including a
                    number of large debt-defaults and insolvencies.
                  </p>
                  <span>
                    Our work has been exclusively for foreign creditors and
                    equity investors, and has included:
                  </span>
                  <ul>
                    <li>
                      Successful enforcement of a debt against a major
                      Indonesian bank
                    </li>
                    <li>
                      Representation (in conjunction with specialist litigators)
                      in bankruptcy proceedings in the Commercial Court
                    </li>
                    <li>
                      A successful application to the Supreme Court for
                      cassation of a bankruptcy declaration
                    </li>
                    <li>
                      Successful recovery of debts by actions against guarantors
                    </li>
                    <li>
                      Acting for clients in the disposal of non-performing loans
                    </li>
                    <li>
                      Carrying out the re-registration of security following
                      transfers of loans
                    </li>
                    <li>Negotiating restructurings</li>
                    <li>
                      Advising on the protection and enforcement of security
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Tele" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Telecommunications, Media & Technology</h2>
                  <p>
                    Our lawyers have acted for Fortune 500 telecommunications,
                    media and IT companies on a broad range of matters and have
                    provided services ranging from regulatory advice to the
                    negotiation of joint ventures.
                  </p>
                  <span>This work has included:</span>
                  <ul>
                    <li>
                      Equity investment in basic and mobile telecommunications
                      operators
                    </li>
                    <li>
                      Representation in negotiations with the Indonesian
                      Government
                    </li>
                    <li>
                      Project financing of a CDMA2000 mobile telephony network
                    </li>
                    <li>Project financing of a basic telephony build-out</li>
                    <li>Interconnection and service level agreements</li>
                    <li>Transponder leases</li>
                    <li>Satellite broadcasting</li>
                    <li>Frequency allocation</li>
                    <li>Due diligence on telecommunications companies</li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
            <motion.div>
              {Data === "Trade" && (
                <motion.div
                  initial={{
                    opacity: 0,
                  }}
                  whileInView={{
                    opacity: 1,
                  }}
                  transition={{
                    duration: 0.5,
                    delay: 0.2,
                  }}
                  viewport={{ once: true }}
                >
                  <h2>Trade & Commerce</h2>
                  <p>
                    We routinely act for foreign manufacturing, trading and
                    service companies on commercial matters, including:
                  </p>
                  <ul>
                    <li>Distribution agreements</li>
                    <li>Advice on the regulatory environment</li>
                    <li>Long-term marketing and off-take agreements</li>
                    <li>
                      Foreign participation in export, import, wholesale and
                      retail
                    </li>
                    <li>Foreign participation in the service sector</li>
                    <li>
                      Trade finance (particularly structured trade finance)
                    </li>
                    <li>
                      The establishment of representative offices, joint
                      ventures and subsidiaries
                    </li>
                    <li>
                      Restructuring distributorships as joint ventures or
                      subsidiaries
                    </li>
                  </ul>
                </motion.div>
              )}
            </motion.div>
          </div>
        </div>
        <div className="Section-3-Content-Mobile">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Mining & Natural Resouces</Accordion.Header>
              <Accordion.Body>
                <h2>Mining & Natural Resouces</h2>
                <p>
                  Our lawyers have worked on numerous mining projects throughout
                  Indonesia, acting for sponsors, lenders, operators and
                  off-takers on virtually all aspects of mining.
                </p>
                <p>In particular, we have advised clients with regard to:</p>
                <ul>
                  <li>Authorizations to mine (ore) and Contracts of Work</li>
                  <li>Environmental and other regulatory matters</li>
                  <li>Technical services and operating agreements</li>
                  <li>Purchase and sale agreements for minerals</li>
                  <li>
                    Collateral management and stockpile management agreements
                  </li>
                  <li>Security over minerals and commodities</li>
                  <li>Sale and acquisition of mining projects</li>
                </ul>
                <p>
                  Our lawyers have also advised clients on financing and
                  corporate matters in the plantation sector.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Competition</Accordion.Header>
              <Accordion.Body>
                <h2>Competition</h2>
                <p>
                  Indonesia has quite comprehensive legislation on monopolies
                  and unfair business practices in 1999, the enforcement of
                  which is overseen by the Business Competition Supervisory
                  Commission (or “KPPU”).
                </p>
                <p>
                  Our lawyers have advised extensively on competition law,
                  including the Indonesian aspects of major cross-border mergers
                  and acquisitions, restrictions on vertical integration and the
                  imposition of restraints on distributors.
                </p>
                <p>
                  We also act for clients in making submissions to the KPPU.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>Employment</Accordion.Header>
              <Accordion.Body>
                <h2>Employment</h2>
                <p>
                  We are regularly called upon by clients to advise on
                  employment law matters which often arise in connection with
                  the establishment of wholly foreign-owned companies in
                  Indonesia, the acquisition of Indonesian companies and other
                  foreign investment transactions.
                </p>
                <span>
                  The firm offers a full range of employment law services to
                  meet the needs of clients in this increasingly complex area of
                  Indonesian law. In particular, we advise on:
                </span>
                <ul>
                  <li>Employment agreements</li>
                  <li>
                    Employment and residency visas for expatriate employees
                  </li>
                  <li>Company regulations and collective labour agreements</li>
                  <li>Mandatory training programs</li>
                  <li>Government social security plans (BPJS)</li>
                  <li>Employee termination and severance</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>Oil & Gas</Accordion.Header>
              <Accordion.Body>
                <h2>Oil & Gas</h2>
                <p>
                  Our lawyers have acted for major oil & gas companies in the
                  acquisition and development of hydrocarbon assets by way of
                  corporate purchases, asset purchases and farm-ins, including
                  some of the largest transactions in Indonesia in recent years.
                  This has entailed work on production sharing contracts,
                  technical assistance contracts, joint operating agreements,
                  and due diligence on existing operations.
                </p>
                <p>
                  We have also advised clients (including oil majors) on
                  abandonment, unitization, procurement, and numerous other
                  aspects of oilfield development and operation.
                </p>
                <p>
                  In addition, we have acted on various matters relating to
                  offshore and onshore oilfield services, including entry into
                  the Indonesian market by the establishment of Indonesian
                  subsidiaries and the acquisition of shares in existing market
                  players.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
              <Accordion.Header>
                Real Estate, Construction & Hotels
              </Accordion.Header>
              <Accordion.Body>
                <h2>Real Estate, Construction & Hotels</h2>
                <p>
                  We have advised developers and contractors on a wide range of
                  property developments.
                </p>
                <span>
                  In addition, we routinely handle the real property aspects of
                  other projects. Our expertise includes:
                </span>
                <ul>
                  <li>Land procurement issues</li>
                  <li>
                    Environmental impact assessment and permitting requirements
                  </li>
                  <li>Development agreements</li>
                  <li>Leases</li>
                  <li>Title investigations</li>
                  <li>Building and engineering contracts</li>
                  <li>Consultancy agreements</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
              <Accordion.Header>Restructuring & Insolvency</Accordion.Header>
              <Accordion.Body>
                <h2>Restructuring & Insolvency</h2>
                <p>
                  In the decade since the 1997 financial crisis, our lawyers
                  have worked on all aspects of restructuring, including a
                  number of large debt-defaults and insolvencies.
                </p>
                <span>
                  Our work has been exclusively for foreign creditors and equity
                  investors, and has included:
                </span>
                <ul>
                  <li>
                    Successful enforcement of a debt against a major Indonesian
                    bank
                  </li>
                  <li>
                    Representation (in conjunction with specialist litigators)
                    in bankruptcy proceedings in the Commercial Court
                  </li>
                  <li>
                    A successful application to the Supreme Court for cassation
                    of a bankruptcy declaration
                  </li>
                  <li>
                    Successful recovery of debts by actions against guarantors
                  </li>
                  <li>
                    Acting for clients in the disposal of non-performing loans
                  </li>
                  <li>
                    Carrying out the re-registration of security following
                    transfers of loans
                  </li>
                  <li>Negotiating restructurings</li>
                  <li>
                    Advising on the protection and enforcement of security
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
              <Accordion.Header>Trade & Commerce</Accordion.Header>
              <Accordion.Body>
                <h2>Trade & Commerce</h2>
                <p>
                  We routinely act for foreign manufacturing, trading and
                  service companies on commercial matters, including:
                </p>
                <ul>
                  <li>Distribution agreements</li>
                  <li>Advice on the regulatory environment</li>
                  <li>Long-term marketing and off-take agreements</li>
                  <li>
                    Foreign participation in export, import, wholesale and
                    retail
                  </li>
                  <li>Foreign participation in the service sector</li>
                  <li>Trade finance (particularly structured trade finance)</li>
                  <li>
                    The establishment of representative offices, joint ventures
                    and subsidiaries
                  </li>
                  <li>
                    Restructuring distributorships as joint ventures or
                    subsidiaries
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Banking & Finance</Accordion.Header>
              <Accordion.Body>
                <h2>Banking & Finance</h2>
                <p>
                  We represent international lenders, sponsors and borrowers in
                  a broad array of banking and finance transactions in
                  Indonesia, particularly project financings, structured
                  commodity finance, and other forms of asset-based finance.
                </p>
                <p>
                  Our lawyers regularly assist in structuring, negotiating,
                  documenting and closing financings. We have particular
                  expertise in structuring, drafting, executing and perfecting
                  Indonesian security packages, including security over land,
                  plant & equipment, receivables, commodities and shares.
                </p>
                <p>
                  We also carry out due diligence, prepare filings in connection
                  with foreign debt registration and provide Indonesian legal
                  opinions.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>Disputes</Accordion.Header>
              <Accordion.Body>
                <h2>Disputes</h2>
                <p>
                  The firm is active in defending clients against civil actions
                  brought against them in Indonesian courts and has been
                  consistently successful in this. We also handle arbitration
                  work and can supply expert witnesses on Indonesian law.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>M&A and Capital Markets</Accordion.Header>
              <Accordion.Body>
                <h2>M&A and Capital Markets</h2>
                <p>
                  Our lawyers are thoroughly experienced in mergers and
                  acquisitions, including transactions for Fortune 500
                  companies. Our practice covers a broad range of industries and
                  comprises due diligence exercises, the structuring,
                  negotiation and implementation of joint ventures, share
                  acquisitions, asset acquisitions and corporate
                  reorganizations.
                </p>
                <p>
                  We also assist in obtaining the necessary approvals of the
                  Capital Investment Coordination Board for the establishment of
                  wholly and partially foreign-owned companies and the
                  acquisition by foreign parties of shares in existing
                  Indonesian companies. In addition, our lawyers advise foreign
                  investors on Indonesian capital markets legislation and
                  regulations in connection with the acquisition of shares in
                  companies listed on the Indonesia Stock Exchanges.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7">
              <Accordion.Header>Power & Infrastructure</Accordion.Header>
              <Accordion.Body>
                <h2>Power & Infrastructure</h2>
                <p>
                  We advise on a wide array of power generation and
                  infrastructure-related matters in Indonesia.
                </p>
                <span>
                  Our lawyers have acted for sponsors, lenders, equity
                  investors, off-takers and contractors, and are experienced in
                  all key project documentation, including:
                </span>
                <ul>
                  <li>Limited and non-recourse credit facilities</li>
                  <li>Direct agreements and consents</li>
                  <li>Joint venture and shareholders agreements</li>
                  <li>Transportation agreements</li>
                  <li>Off-take agreements</li>
                  <li>Fuel supply agreements</li>
                  <li>O&M agreements</li>
                  <li>EPC contracts</li>
                </ul>
                <span>
                  We also advise clients with regard to relevant regulatory
                  requirements, including environmental law and land usage.
                </span>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="9">
              <Accordion.Header>Shipping & Logistics</Accordion.Header>
              <Accordion.Body>
                <h2>Shipping & Logistics</h2>
                <span>
                  We have advised on a broad range of contentious and
                  non-contentious matters in the shipping and logistics
                  industries. These have included:
                </span>
                <ul>
                  <li>Advising on the arrest of vessels</li>
                  <li>Advising on issues arising with the Indonesian Navy</li>
                  <li>
                    The establishment of joint ventures in the shipping sector
                  </li>
                  <li>
                    Advice on regulatory issues in the shipping and logistics
                    sector, particularly with regard to foreign participation
                  </li>
                  <li>Advice on the effects of Indonesia’s cabotage laws</li>
                  <li>Assistance in obtaining shipping licences</li>
                  <li>
                    Corporate restructuring in the shipping and logistics
                    industries
                  </li>
                  <li>Expert testimony on Indonesian law</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="11">
              <Accordion.Header>
                Telecommunications, Media & Technology
              </Accordion.Header>
              <Accordion.Body>
                <h2>Telecommunications, Media & Technology</h2>
                <p>
                  Our lawyers have acted for Fortune 500 telecommunications,
                  media and IT companies on a broad range of matters and have
                  provided services ranging from regulatory advice to the
                  negotiation of joint ventures.
                </p>
                <span>This work has included:</span>
                <ul>
                  <li>
                    Equity investment in basic and mobile telecommunications
                    operators
                  </li>
                  <li>
                    Representation in negotiations with the Indonesian
                    Government
                  </li>
                  <li>
                    Project financing of a CDMA2000 mobile telephony network
                  </li>
                  <li>Project financing of a basic telephony build-out</li>
                  <li>Interconnection and service level agreements</li>
                  <li>Transponder leases</li>
                  <li>Satellite broadcasting</li>
                  <li>Frequency allocation</li>
                  <li>Due diligence on telecommunications companies</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
export default Expertise;
