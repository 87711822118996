import React from 'react'
import "../Style/People.scss"
import Banner from "../Assets/People/BANNER.png";
import Andi from "../Assets/People/Andi.png";
import Dina from "../Assets/People/Dina.png";
import {motion} from "framer-motion"
function People() {
    return (
        <div className='People-Container'>
            <div className='People-Section-1'>
                <motion.img
                initial={{
                    opacity:0,
                  }}
                  whileInView={{
                    opacity:1,
                  }}
                  transition={{
                    duration:0.5,
                    delay:0.2
                  }}
                  viewport={{ once: true}}
                src={Banner} />
                <div className='People-Content'>
                    <motion.h1
                    initial={{
                        opacity:0,
                        y:100
                      }}
                      whileInView={{
                        opacity:1,
                        y:0
                      }}
                      transition={{
                        duration:0.5,
                        delay:0.4
                      }}
                      viewport={{ once: true}}
                    >Exceptional Team</motion.h1>
                    <motion.p
                    initial={{
                        opacity:0,
                        y:100
                      }}
                      whileInView={{
                        opacity:1,
                        y:0
                      }}
                      transition={{
                        duration:0.5,
                        delay:0.6
                      }}
                      viewport={{ once: true}}
                      >Depth of experience working with Indonesian and
                        <span>international clients.</span>
                    </motion.p>
                </div>

            </div>
            <div className='People-Section-2'>
                <div className='People-Title'>
                    <motion.h2
                    initial={{
                        opacity:0,
                        y:100
                      }}
                      whileInView={{
                        opacity:1,
                        y:0
                      }}
                      transition={{
                        duration:0.5,
                        delay:0.2
                      }}
                      viewport={{ once: true}}
                    >Lawyers</motion.h2>
                </div>
                <motion.span
                initial={{
                    width: "0%"
                  }}
                  whileInView={{
                    width: "100%"
                  }}
                  transition={{
                    duration:0.5,
                    delay: 0.2
                  }}
                  viewport={{ once: true}}
                className='Line' />
                <div className='Biography'>
                    <div className='Left'>
                        <motion.img
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        src={Andi} />
                    </div>
                    <div className='Right'>
                        <motion.h3
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        >Andi Zulfikar</motion.h3>
                        <motion.p
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        >
                            Andi graduated from Faculty of Law of University of Indonesia,
                            the country premier law school, in 1994 and was admitted as an Indonesian advocate the following year. Andi practiced law for more than 20 years.
                            He was an associate with the Indonesian affiliate of Baker & McKenzie before moving to Singapore in 2000 to join Oliver Wright at Denton Wilde Sapte, where he worked for four years.
                            He then moved back to Jakarta, and together with Oliver, founded Mataram Partners in 2006. Following the retirement of Oliver, Andi solely leads Mataram Partners since 2012.
                            <motion.span
                            initial={{
                                opacity:0,
                                y:100
                              }}
                              whileInView={{
                                opacity:1,
                                y:0
                              }}
                              transition={{
                                duration:0.5,
                                delay:0.2
                              }}
                              viewport={{ once: true}}
                            >
                                Andi speaks and writes excellent English. He is particularly valued by clients for his negotiating and problem-solving skills and is a well-known figure in the Jakarta business and legal communities.
                            </motion.span>
                        </motion.p>
                        <motion.div
                        initial={{
                            opacity:0,
                          }}
                          whileInView={{
                            opacity:1,
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.4
                          }}
                          viewport={{ once: true}}
                        className='People-Contact'>
                            <span>Contact :</span>
                            <a href='mailto:andi.zulfikar@mataramlaw.com'>
                                andi.zulfikar@mataramlaw.com
                            </a>
                        </motion.div>
                    </div>
                </div>
                < motion.span
                initial={{
                    width: "0%"
                  }}
                  whileInView={{
                    width: "100%"
                  }}
                  transition={{
                    duration:1,
                    delay: 0.2
                  }}
                  viewport={{ once: true}}
                className='Line' />
                <div className='Biography'>
                    <div className='Left'>
                        <motion.img
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        src={Dina} />
                    </div>
                    <div className='Right'>
                        <motion.h3
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        >Dina Karina</motion.h3>
                        <motion.p
                        initial={{
                            opacity:0,
                            y:100
                          }}
                          whileInView={{
                            opacity:1,
                            y:0
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.2
                          }}
                          viewport={{ once: true}}
                        >
                            Dina graduated from Faculty of Law of University of Indonesia in 2007.
                            She joined Mataram Partners shortly after her graduation.
                            <motion.span
                            initial={{
                                opacity:0,
                                y:100
                              }}
                              whileInView={{
                                opacity:1,
                                y:0
                              }}
                              transition={{
                                duration:0.5,
                                delay:0.2
                              }}
                              viewport={{ once: true}}
                            >
                                Dina main practice areas include corporate & investment, mining & energy, technology & IT, labour, project finance and commercial transactions.
                                Dina speaks English and Indonesian language.
                            </motion.span>
                        </motion.p>
                        <motion.div
                        initial={{
                            opacity:0,                          }}
                          whileInView={{
                            opacity:1,
                          }}
                          transition={{
                            duration:0.5,
                            delay:0.4
                          }}
                          viewport={{ once: true}}
                        className='People-Contact'>
                            <span>Contact :</span>
                            <a href="mailto:dina.karina@mataramlaw.com">
                                dina.karina@mataramlaw.com
                            </a>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default People;
