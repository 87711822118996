import React from 'react';
import '../Style/Nav.scss';
import Logo from "../Assets/mataram-logo 1.jpg";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import $ from "jquery";
import { motion} from "framer-motion";
function Nav() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const ToggleButton = () =>{
        if(show){
            $(".modal-dialog").addClass("show-off");
            $(".Button-Close").addClass("d-none");
            $(".Button-Menu").removeClass("d-none");
            $(".modal-dialog").removeClass("show-on");
            setTimeout(function () {
                setShow(false);
              }, 1200);
        } else {
            $(".Button-Close").removeClass("d-none");
            $(".Button-Menu").addClass("d-none");
            setShow(true);
        }
    }
    return (
        <div className='Nav-Container'>
            <div className='Nav-Wrapper'>
                <div className='Nav-Left-Section'>
                    <a href='/'>
                        <motion.img
                        initial={{
                            opacity:0
                        }}
                        whileInView={{
                            opacity:1
                        }}
                        transition={{
                            duration:1,
                            delay: 0.2
                        }}
                        viewport={{
                            once:true
                        }}
                        className='Logo-Nav' src={Logo} />
                    </a>
                </div>
                <div className='Nav-Right-Section'>
                    <div className='Mobile'>
                        <Button variant="primary"  onClick={ToggleButton}>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:1,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            className='Button-Menu '>Menu</motion.span>
                            <span className='Button-Close d-none'>Close</span>
                        </Button>
                    </div>
                    <div className='Context'>
                        <a href='/about' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >About Us</motion.span>
                        </a>
                        <a href='/expertise' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >Expertise</motion.span>
                        </a>
                        <a href='/lawyers' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >Lawyers</motion.span>
                        </a>
                        <a href='/news' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >News</motion.span>
                        </a>
                        <a href='/careers' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >Careers</motion.span>
                        </a>
                        <a href='/contact' className='margin-10'>
                            <motion.span
                            initial={{
                                opacity:0
                            }}
                            whileInView={{
                                opacity:1
                            }}
                            transition={{
                                duration:0.5,
                                delay: 0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            >Contact</motion.span>
                        </a>
                    </div>

                    <Modal show={show} onHide={handleClose} animation={false} dialogClassName="show-on">
                        <Modal.Body>
                            <motion.a
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/'>
                                <span>Home</span>
                            </motion.a>
                            <motion.a 
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.1
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/about'>
                                <span>About Us</span>
                            </motion.a>
                            <motion.a
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.2
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/expertise'>
                                <span>Expertise</span>
                            </motion.a>
                            <motion.a
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.3
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/lawyers'>
                                <span>Laywers</span>
                            </motion.a>
                            <motion.a
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.4
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/news'>
                                <span>News & Events</span>
                            </motion.a>
                            <motion.a
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.5
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/careers'>
                                <span>Careers</span>
                            </motion.a>
                            <motion.a 
                            initial={{
                                opacity:0,
                                y:-50
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.4,
                                delay:0.6
                            }}
                            viewport={{
                                once:true
                            }}
                            href='/contact'>
                                <span>Contact</span>
                            </motion.a>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        </div>
    )
}
export default Nav;
