import {React, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import ProductAPI from '../Config/ProductAPI';
import "../Style/NewsDetail.scss"
import {motion} from "framer-motion"
function NewsDetail() {
  // const URL = "https://mataramlaw-cms.com/wp-json/wp/v2"
  const { id } = useParams();
  const [Detail, setDetail] = useState({});
  const [Loading, setLoading] = useState(true);
  useEffect(()=>{
    ProductAPI.detail(id).then((res) =>{
      console.log(res)
      setDetail(res)
      setLoading(false)
    })
  },[]);

   console.log(Detail)  
  if (Loading) {
    return <h3>Wait a sec</h3>
  }
  return (
    <div className='Detail-Container' >
        <motion.h1
        initial={{
          opacity:0
        }}
        whileInView={{
          opacity:1
        }}
        transition={{
          duration:0.5,
          delay: 0.2
        }}
        viewport={{
          once:true
        }}
        >{Detail.acf.news_title}</motion.h1>
        <motion.img
        initial={{
          opacity:0
        }}
        whileInView={{
          opacity:1
        }}
        transition={{
          duration:0.5,
          delay: 0.4
        }}
        viewport={{
          once:true
        }}
        src={`https://mataramlaw-cms.com/wp-content/uploads/2023/03/${Detail.acf.image.filename}`} />
        <motion.div
        initial={{
          opacity:0
        }}
        whileInView={{
          opacity:1
        }}
        transition={{
          duration:0.5,
          delay: 0.4
        }}
        viewport={{
          once:true
        }}
        dangerouslySetInnerHTML={{__html:Detail.content.rendered}}></motion.div>
    </div>
  )
}

export default NewsDetail;