import  {React, useEffect, useState} from 'react';
import "../Style/Home.scss";
import Image1 from "../Assets/home-photo.webp";
import Image2 from "../Assets/home-2.webp";
import Imageresources1 from "../Assets/image 1.jpg";
import Imageresources2 from "../Assets/image 2.jpg";
import Imageresources3 from "../Assets/image 3.jpg";
import { motion} from "framer-motion"
import Axios from 'axios';
function Home() {

  const [Products,setProducts] = useState([])
  useEffect(() => {
    Axios.get("https://mataramlaw-cms.com/wp-json/wp/v2/news?per_page=3").then(response => {
        
        setProducts(response.data);
    });
}, [setProducts]);
console.log(Products)
  return (
    <div className='Home-Container'>
      <div className='Home-Section-1' >
        <motion.h1  
        initial={{
          opacity:0,
          y:100
        }}
        whileInView={{
          opacity:1,
          y:0
        }}
        transition={{
          duration:0.5
        }}
        viewport={{ once: true}}
        >
          Trusted<span>Indonesian</span>
          <span>
            Legal Adviser</span>
        </motion.h1>
        <motion.p
        initial={{
          opacity:0,
          y:100
        }}
        animate={{
          opacity:1,
          y:0
        }}
        transition={{
          duration:0.5,
          delay:0.2
        }}
        viewport={{ once: true}}
        >Providing tailored, practical and innovative legal solutions since 2006.</motion.p>
      </div>
      <div className='Home-Section-2' >
        <motion.span
        initial={{
          width:"0%"
        }}
        whileInView={{
          width:"100%"
        }}
        transition={{
          duration:0.5,
          delay:0.2
        }}
        viewport={{ once: true}}
        className='line' />
        <div className='Content-Section-2'>
          <motion.p
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          >Mataram Partners is an Indonesian law firm specializing in foreign investor services in Indonesia.
            <span>Our dedicated team is comprised of legal advisers with a diverse array of academic and professional backgrounds, fully fluent in English. The legal advice we provide to our varied international clients is clear, concise and engaging with a sound knowledge of Indonesian law.</span>
            <a href='/about'>
              <span>
                Read More
              </span>
            </a>
          </motion.p>
          <motion.img
          initial={{
            opacity: 0
          }}
          whileInView={{
            opacity:1
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          className='img' src={Image1} />
        </div>
        <motion.span
        initial={{
          width:"0%"
        }}
        whileInView={{
          width:"100%"
        }}
        transition={{
          duration:0.8,
          delay:0.2
        }}
        viewport={{ once: true}}
        className='line' />
      </div>
      <div className='Home-Section-3' >
        <div className='content-sec-3'>
          <motion.img
          initial={{
            opacity:0
          }}
          whileInView={{
            opacity:1
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          className='Section-3bg' src={Image2} />
          <div className='Section-3-Text'>
            <motion.h2
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.4
            }}
            viewport={{ once: true}}
            >Understanding your
              <span>business</span>
            </motion.h2>
            <motion.p
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.6
            }}
            viewport={{ once: true}}
            >Depth of experience working with Indonesian and <span>international clients.</span></motion.p>
            <motion.a  
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.8
            }}
            viewport={{ once: true}}
            href='/expertise'>
              <span>Expertise</span>
            </motion.a>
          </div>
        </div>
      </div>
      <div className='Home-Section-4' >
        <motion.span
        initial={{
          width:"0%"
        }}
        whileInView={{
          width:"100%"
        }}
        transition={{
          duration:0.8,
          delay:0.2
        }}
        viewport={{ once: true}}
        className='line' />
        <div className='Section-4-Content'>
          <div className='Left'>
            <motion.h3
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.2
            }}
            viewport={{ once: true}}
            >Exceptional Team</motion.h3>
          </div>
          <div className='Right'>
            <motion.p
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.4
            }}
            viewport={{ once: true}}
            >Today, all businesses compete on a global stage - we’re passionate about setting new standards to transform internal processes and drive industries forward.</motion.p>
            <motion.a
            initial={{
              opacity:0,
              y:100
            }}
            whileInView={{
              opacity:1,
              y:0
            }}
            transition={{
              duration:0.5,
              delay:0.6
            }}
            viewport={{ once: true}}
            href='/lawyers'>
              <span>Teams</span>
            </motion.a>
          </div>
        </div>
        <motion.span
        initial={{
          width:"0%"
        }}
        whileInView={{
          width:"100%"
        }}
        transition={{
          duration:0.8,
          delay:0.2
        }}
        viewport={{ once: true}}
        className='line' />
      </div>
      <div className='Home-Section-5' >
        <div className='Section-5-Top'>
          <motion.h2
          initial={{
            opacity:0,
            y:100
          }}
          whileInView={{
            opacity:1,
            y:0
          }}
          transition={{
            duration:0.5,
            delay:0.2
          }}
          viewport={{ once: true}}
          >Resources</motion.h2>
        </div>
        <div className='Section-5-Middle'>
            {
              Products && Products.map(props=>{
                return(
                  <div key={props.id} className='Card'>
                                    <div className='Image-Container'>
                                        <motion.img
                                            initial={{
                                                opacity: 0,
                                                y: 100
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.2
                                            }}
                                            viewport={{
                                                once: true
                                            }}
                                            src={`https://mataramlaw-cms.com/wp-content/uploads/2023/03/${props.acf.image.filename}`} />
                                    </div>
                                    <div className='Title-Container'>
                                        <motion.a
                                            initial={{
                                                opacity: 0,
                                                y: 100
                                            }}
                                            whileInView={{
                                                opacity: 1,
                                                y: 0
                                            }}
                                            transition={{
                                                duration: 0.5,
                                                delay: 0.4
                                            }}
                                            viewport={{
                                                once: true
                                            }}
                                            href={`/news/${props.id}`}>
                                            <h2>
                                                {props.acf.news_title}
                                            </h2>
                                        </motion.a>
                                    </div>
                                </div>
                )

              })
            } 
          
        </div>
        <div className='Section-5-Bottom'>
          <a href='/news'>
            <span>More News</span>
          </a>
        </div>
      </div>

    </div>
  )
}
export default Home;