import React from 'react'
import "../Style/Contact.scss";
import { motion} from "framer-motion"
function Contact() {
    return (
        <div className='Contact-Container'>
            <div className='Contact-Section-1'>
                <div className='Contact-Content'>
                    <div className='Left'>
                        <motion.h1
                        initial={{
                            opacity:0,
                            y:100
                        }}
                        whileInView={{
                            opacity:1,
                            y:0
                        }}
                        transition={{
                            duration:0.5,
                            delay:0.2
                        }}
                        viewport={{
                            once: true
                        }}
                        >
                            Contact
                            <span>Mataram Partners</span>
                        </motion.h1>
                        <motion.p
                        initial={{
                            opacity:0,
                        }}
                        whileInView={{
                            opacity:1,
                        }}
                        transition={{
                            duration:1,
                            delay:0.4
                        }}
                        viewport={{
                            once: true
                        }}
                        >
                            If you have any further inquiries or require additional
                            <motion.span>information about Mataram Partners,
                                please do not</motion.span>
                            <motion.span>hesitate to reach out to us.
                                We are always happy to assist</motion.span>
                            and provide more information.
                        </motion.p>
                    </div>
                    <div className='Right' >
                        <motion.div 
                        initial={{
                            opacity:0,
                        }}
                        whileInView={{
                            opacity:1,
                        }}
                        transition={{
                            duration:1,
                            delay:0.4
                        }}
                        viewport={{
                            once: true
                        }}
                        className='Info-Container'>
                            <motion.p
                            initial={{
                                opacity:0,
                                y:100
                            }}
                            whileInView={{
                                opacity:1,
                                y:0
                            }}
                            transition={{
                                duration:0.5,
                                delay:0.2
                            }}
                            viewport={{
                                once: true
                            }}
                            >Treasury Tower, 11th Floor, Units A & B
                                <span>District 8, SCBD Lot. 28</span>
                                <span>Jl. Jend. Sudirman Kav. 52-53</span>
                                <span>Jakarta 12190, Indonesia</span>
                            </motion.p>
                            <a className='margin-20' target="_blank" href="https://goo.gl/maps/jKjo1MTZBPbNNvf56">
                                <motion.span
                                initial={{
                                    opacity:0,
                                }}
                                whileInView={{
                                    opacity:1,
                                }}
                                transition={{
                                    duration:1,
                                    delay:0.4
                                }}
                                viewport={{
                                    once: true
                                }}
                                >Open In Maps</motion.span>
                            </a>
                        </motion.div>
                        <motion.div
                        initial={{
                            opacity:0,
                        }}
                        whileInView={{
                            opacity:1,
                        }}
                        transition={{
                            duration:1,
                            delay:0.4
                        }}
                        viewport={{
                            once: true
                        }}
                        className='Info-Container'>
                            <motion.span
                            initial={{
                                opacity:0,
                            }}
                            whileInView={{
                                opacity:1,
                            }}
                            transition={{
                                duration:1,
                                delay:0.4
                            }}
                            viewport={{
                                once: true
                            }}
                            >Tel: +62 21 5010 5700</motion.span>
                            <motion.span
                            initial={{
                                opacity:0,
                            }}
                            whileInView={{
                                opacity:1,
                            }}
                            transition={{
                                duration:1,
                                delay:0.4
                            }}
                            viewport={{
                                once: true
                            }}
                            >Fax: +62 21 5010 5701</motion.span>
                            <a href="tel:+622150105700" target="_blank" className='margin-20'>
                                <motion.span
                                initial={{
                                    opacity:0,
                                }}
                                whileInView={{
                                    opacity:1,
                                }}
                                transition={{
                                    duration:1,
                                    delay:0.4
                                }}
                                viewport={{
                                    once: true
                                }}
                                >Call Us +</motion.span>
                            </a>
                        </motion.div>
                        <motion.div
                        initial={{
                            opacity:0,
                        }}
                        whileInView={{
                            opacity:1,
                        }}
                        transition={{
                            duration:1,
                            delay:0.4
                        }}
                        viewport={{
                            once: true
                        }}
                        className='Info-Container'>
                            <motion.span
                            initial={{
                                opacity:0,
                            }}
                            whileInView={{
                                opacity:1,
                            }}
                            transition={{
                                duration:1,
                                delay:0.4
                            }}
                            viewport={{
                                once: true
                            }}
                            >info@mataramlaw.com</motion.span>
                            <a href="mailto:info@mataramlaw.com" target="_blank" className='margin-20'>
                                <motion.span
                                initial={{
                                    opacity:0,
                                }}
                                whileInView={{
                                    opacity:1,
                                }}
                                transition={{
                                    duration:1,
                                    delay:0.4
                                }}
                                viewport={{
                                    once: true
                                }}
                                >Email Us</motion.span>
                            </a>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact